<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-form @submit.prevent>
      <b-tabs
        pills
      >
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t('users.chooseuser') }}</span>
          </template>
          <b-card>
            <validation-observer ref="validationAgencySelectMemberRules">
              <div class="head-card">
                <div class="head-card-top">
                  <h4>{{ $t('users.chooseuser') }}</h4>
                  <feather-icon
                    v-b-tooltip.hover.top="$t('Close')"
                    icon="XIcon"
                    size="18"
                    class="toggle-close cursor-pointer"
                    @click="$router.go(-1)"
                  />
                </div>
                <hr>
              </div>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    :rules="{ required, email }"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length == 0 || 'is-invalid'"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LayoutIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-email-s"
                        v-model="memberEmail"
                        placeholder="User email"
                        autocomplete="username"
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="addAgencyMember()"
                        >
                          {{ $t('form.apply') }}
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col
                  md="12"
                  class="d-flex align-items-center justify-content-end mt-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="mr-0 mr-sm-1"
                    @click="$router.go(-1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('form.back') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card>
        </b-tab>
        <!--/ general tab -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserPlusIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">{{ $t('users.newuser') }}</span>
          </template>
          <b-card>
            <validation-observer ref="validationAgencyAddMemberRules">
              <div class="head-card">
                <div class="head-card-top">
                  <h4>{{ $t('page.user.create') }}</h4>
                  <feather-icon
                    v-b-tooltip.hover.top="$t('Close')"
                    icon="XIcon"
                    size="18"
                    class="toggle-close cursor-pointer"
                    @click="$router.go(-1)"
                  />
                </div>
                <hr>
              </div>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Email"
                    label-for="i-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="MailIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-email"
                          v-model="userEmail"
                          type="email"
                          placeholder="john.doe@email.com"
                          autocomplete="username"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Username"
                    label-for="i-username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UserIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-username"
                          v-model="userName"
                          placeholder="johndoe"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="username"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- currency -->
                  <b-form-group
                    label="Account currency"
                    label-for="member-currency"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Currency"
                      vid="currency"
                      rules="required"
                    >
                      <b-form-select
                        v-model="currency"
                        :options="currencyList"
                        size="md"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Phone number"
                    label-for="i-phone"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SmartphoneIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-phone"
                        v-model="userPhone"
                        placeholder="Enter your phone number"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    label="New password"
                    label-for="i-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="New password"
                      vid="Password"
                      rules="required|min:8|password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <b-form-input
                          id="i-password"
                          v-model="passwordNew"
                          :type="passwordFieldTypeNew"
                          :state="errors.length > 0 ? false:null"
                          placeholder="New password"
                          autocomplete="new-password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconNew"
                            class="cursor-pointer"
                            @click="togglePasswordNew"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Confirm Password"
                    label-for="i-c-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Re-type Password"
                      rules="required|confirmed:Password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length == 0 || 'is-invalid'"
                      >
                        <b-form-input
                          id="i-c-password"
                          v-model="passwordRetype"
                          :state="errors.length > 0 ? false:null"
                          :type="passwordFieldTypeRetype"
                          placeholder="Re-type Password"
                          autocomplete="new-password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIconRetype"
                            class="cursor-pointer"
                            @click="togglePasswordRetype"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col
                  md="12"
                  class="d-flex align-items-center justify-content-end mt-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="mr-0 mr-sm-1"
                    @click="$router.go(-1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('form.back') }}</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="createMember()"
                  >
                    <span class="align-middle">{{ $t('form.create-acc') }}</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="ml-50"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-card>
        </b-tab>
        <!--/ general tab -->
      </b-tabs>
    </b-form>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  BFormSelect,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  integer,
  min,
  email,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import Ripple from 'vue-ripple-directive'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    Breadcrumbs,
    BForm,
    BFormGroup,
    BFormSelect,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      pageTitle: '',
      pageSubtitle: '',
      email,
      min,
      required,
      integer,
      account: {},
      authUserData: getUserData(),
      agency: [],
      breadcrumbItems: [],
      memberEmail: '',
      userName: '',
      userEmail: '',
      userPhone: '',
      passwordNew: '',
      passwordRetype: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      currencyList: [
        { text: 'Select currency', value: null },
        { text: 'USD', value: 'USD' },
        { text: 'UAH', value: 'UAH' },
        { text: 'RUB', value: 'RUB' },
        { text: 'EUR', value: 'EUR' },
        { text: 'ILS', value: 'ILS' },
        { text: 'UZS', value: 'UZS' },
        { text: 'BYN', value: 'BYN' },
        { text: 'BRL', value: 'BRL' },
        { text: 'INR', value: 'INR' },
        { text: 'THB', value: 'THB' },
      ],
      currency: null,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.getUserData()
  },
  methods: {
    addAgencyMember() {
      this.$refs.validationAgencySelectMemberRules.validate().then(async success => {
        if (success) {
          await useJwt.addAgencyMember(this.$route.params.id, { email: this.memberEmail })
            .then(response => {
              const member = response.data
              useJwt.setUserRoles(member.user_id, { roles: ['AgencyMember'] })
              this.$router.push(`/users/${this.$route.params.userId}/agencies/${this.$route.params.id}/${member.user_id}/edit/`)
            })
        }
      })
    },
    async getAgencyData() {
      const responseData = await useJwt.getAgency(this.$route.params.id)
      this.agency = responseData.data.agency || []
      this.setBreabcrumbs()
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.getAgencyData()
    },
    async createMember() {
      this.$refs.validationAgencyAddMemberRules.validate().then(async success => {
        if (success) {
          const newMember = {
            email: this.userEmail,
            name: this.userName,
            password: this.passwordNew,
            phone: this.userPhone,
            balance: 0,
            is_admin: false,
            created_at: null,
            currency: this.currency,
            owns: null,
            is_agency_member: false,
            is_agency: false,
            roles: [
              'AgencyMember',
            ],
            show_amount: false,
            skype: '',
            notes: '',
            affiliate: '',
            affiliate_earn_percent: 5,
          }

          await useJwt.newUser({ account: newMember })
            .then(newAccountResponse => {
              if (newAccountResponse) {
                useJwt.addAgencyMember(this.$route.params.id, { email: this.userEmail })
                  .then(response => {
                    const member = response.data
                    useJwt.setUserRoles(member.user_id, { roles: ['AgencyMember'] })
                    this.$router.push(`/users/${this.$route.params.userId}/agencies/${this.$route.params.id}/${member.user_id}/edit/`)
                  })
              }
            })
        }
      })
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('Users'),
          to: '/users/',
        },
        {
          text: this.account.name,
          to: `/users/${this.agency.owner}`,
        },
        {
          text: this.$t('Partners'),
          to: `/users/${this.agency.owner}/agencies`,
        },
        {
          text: this.agency.title,
          to: `/users/${this.agency.owner}/agencies/${this.agency._id}`,
        },
        {
          text: this.$t('page.user.create'),
          active: true,
        },
      ]
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
